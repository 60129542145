import { Link } from 'react-router-dom'
import { NavLink } from "react-router-dom"

import './SiteFooter.scss'


export default function SiteFooter() {
    return (
        <footer className='site-footer container'>
            {/* <a href="https://www.freepik.com/vectors/postcard-stamp">Postcard stamp vector created by tartila - www.freepik.com</a> */}
        </footer>
    )
}